<template>
  <section
    :id="sectionId"
    v-editable="blok"
    :style="[sectionStyle, backgroundColor]"
    :data-section-uid="blok._uid"
    class="page-section"
    :class="[
      paddingBottom,
      paddingTop,
      { 'page-section--overflow-x-disabled': isOverflowXDisabled },
    ]"
  >
    <div
      :class="[
        { container: !isSectionFullWidth },
        { 'container--narrow': isSectionNarrow },
        { 'container--wide': isSectionWide },
      ]"
      :style="containerPaddingStyle"
    >
      <StoryblokComponent
        v-for="childBlok in blok.blocks"
        :key="childBlok._uid"
        :blok="childBlok"
      />
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { SbSectionStoryblok } from '@/types'
import { slugify } from '@/utils/helpers'

interface Props {
  blok: SbSectionStoryblok
  index?: number
}
const props = defineProps<Props>()

const OPTIONS = {
  IS_NARROW: 'isNarrow',
  IS_WIDE: 'isWide',
  IS_FULL_WIDTH: 'isFullWidth',
}

const PADDING = {
  MOBILE: '20px',
  TABLET: '128px',
  DESKTOP: '20px',
}

const sectionStyle = computed(() => {
  if (!props.blok.use_gradient) return null

  const gradient = `${props.blok.direction}, ${props.blok.color_from.value}, ${props.blok.color_to.value}`

  return {
    background: `linear-gradient(${gradient})`,
  }
})

// use state is used to pass the bg color to the video player to avoid passing props
useState('bgColor', () => props.blok.bg_color?.value || '#FFFFFF')

const paddingBottom = computed(() => {
  return `pb-${props.blok.padding_bottom}` || 'pb-0'
})
const paddingTop = computed(() => {
  return `pt-${props.blok.padding_top}` || 'pt-0'
})
const backgroundColor = computed(() => {
  if (props.blok.use_gradient) return ''

  return {
    backgroundColor: props.blok.bg_color?.value || '#FFFFFF',
  }
})
const isSectionNarrow = computed(() => {
  return props.blok.width === OPTIONS.IS_NARROW
})
const isSectionWide = computed(() => {
  return props.blok.width === OPTIONS.IS_WIDE
})
const isSectionFullWidth = computed(() => {
  return props.blok.width === OPTIONS.IS_FULL_WIDTH
})
const isOverflowXDisabled = computed(() => {
  return props.blok.is_overflow_x_disabled
})
const sectionId = computed(() => props.blok.name && slugify(props.blok.name))

const containerPaddingStyle = computed(() => {
  return {
    '--padding-mobile': props.blok.is_padding_disabled?.desktop
      ? 0
      : PADDING.MOBILE,
    '--padding-tablet': props.blok.is_padding_disabled?.tablet
      ? 0
      : PADDING.TABLET,
    '--padding-desktop': props.blok.is_padding_disabled?.desktop
      ? 0
      : PADDING.DESKTOP,
  }
})
</script>
<style lang="scss" scoped>
.container {
  --container-padding: var(--padding-mobile);

  @media (min-width: $breakpoint-md) {
    --container-padding: var(--padding-tablet);
  }

  @media (min-width: $breakpoint-lg) {
    --container-padding: var(--padding-desktop);
  }
}

.page-section {
  overflow-x: hidden;

  &--overflow-x-disabled {
    overflow-x: visible;
  }
}
</style>
